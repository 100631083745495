import React, { useEffect, useState } from 'react'
import InputField from "../../ui/InputField"

import './style.scss'

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [content, setContent] = useState("")
  const [status, setStatus] = useState("")
  const [message, setMessage] = useState("")
  const [errorMsg, setError] = useState("")

  var formdata

  if (typeof FormData !== "undefined") {
    formdata = new FormData()
    formdata.append("your-name", name)
    formdata.append("your-email", email)
    formdata.append("your-subject", subject)
    formdata.append("your-message", content)
  }

  

  const options = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  }

  function handleReset() {
    setName("")
    setEmail("")
    setSubject("")
    setContent("")
  }

  const handleSubmit = e => {
    e.preventDefault()
    setStatus("sending")
    function sendForm() {
      fetch(
        "https://awra-group.org/wp-json/contact-form-7/v1/contact-forms/290/feedback",
        options
      )
        .then(response => response.json())
        .then(
          result => (
            setMessage(result.message) &&
            result.status === 'mail_sent' ? (setStatus("sent") && handleReset()) : setStatus("error")
            
          )
        )
        .catch(error => (setError(error.message) && setStatus("error")))
    }
    sendForm()
  }

  useEffect(() => {
    return () => {
      handleReset()
    }
  }, [])

  return (
    <section className="contact">
      <form className="form-wrapper" onSubmit={e => handleSubmit(e)}>
        {status === "error" ? (
          <div className="notification is-danger is-light">
            {message || errorMsg}
          </div>
        ) : (
          ""
        )}
        {status === "sent" ? (
          <div className="notification is-success is-light">
            {message}
          </div>
        ) : (
          ""
        )}
        <div className="form-body">
        <p className="intro-title">
            If you have any question, please don't hesitate to communicate with us.
          </p>
        <InputField
            onChangeHandler={setName}
            type="text"
            value={name}
            placeholder="Name"
            isRequired
          />
          <InputField
            onChangeHandler={setEmail}
            type="email"
            value={email}
            placeholder="Email"
            isRequired
          />
        <InputField
            onChangeHandler={setSubject}
            type="text"
            value={subject}
            placeholder="Subject"
            isRequired
          />
          <InputField
            onChangeHandler={setContent}
            type="textarea"
            value={content}
            placeholder="Message..."
            isRequired
          />
        </div>
      <div className="button-wrapper">
            <button
              className="button is-dark"
              type="submit"
              disabled={status === "sending" ? true : false}
            >
              Submit
            </button>
          </div>
      </form>
    </section>
  )
}

export default ContactForm
