import React from 'react'
import { VolunteerForm } from '../components/forms'
import Layout from '../components/layout'
import Seo from '../components/seo'

function Volunteer() {
  return (
    <Layout>
      <Seo title="Volunteer" />
      <VolunteerForm />
    </Layout>
  )
}

export default Volunteer