import React, { useEffect, useState } from "react"

import InputField from "../../ui/InputField"
import { countriesList } from "/static/JSON/countries"

import "./style.scss"

const VolunteerForm = () => {
  const [countries, setCountries] = useState([])
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [title, setTitle] = useState("")
  const [country, setCountry] = useState("Afghanistan")
  const [brief, setBrief] = useState("")
  const [achievements, setAchievements] = useState("")
  const [publishedWorks, setPublishedWorks] = useState("")
  const [status, setStatus] = useState("")
  const [message, setMessage] = useState("")
  const [errorMsg, setError] = useState("")

  var formdata

  if (typeof FormData !== "undefined") {
    formdata = new FormData()

    formdata.append("vName", name)
    formdata.append("email", email)
    formdata.append("role", role)
    formdata.append("title", title)
    formdata.append("country", country)
    formdata.append("bio", brief)
    formdata.append("achievements", achievements)
    formdata.append("publish", publishedWorks)
  }

  const options = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  }

  function handleReset() {
    setName("")
    setEmail("")
    setRole("")
    setTitle("")
    setCountry("")
    setBrief("")
    setAchievements("")
    setPublishedWorks("")
  }

  const handleSubmit = e => {
    e.preventDefault()
    setStatus("sending")
    function sendForm() {
      fetch(
        "https://wp.awra-group.org/wp-json/contact-form-7/v1/contact-forms/508/feedback",
        options
      )
        .then(response => response.json())
        .then(
          result => (
            result.status === 'mail_sent' ? (setStatus("sent"), setMessage(result.message), handleReset()) : setStatus("error")
          )
        )
        .catch(error => (setError(error.message) && setStatus("error")))
    }
    sendForm()
  }

  const handleChange = async ev => {
    const {
      target: { value },
    } = ev
    // setAmount(0)
    setCountry(value)
  }

  useEffect(() => {
    setCountries(countriesList)
    return () => {
      setCountries([])
      handleReset()
    }
  }, [])

  return (
    <section className="container volunteer">
      <header className="form-header">
        <h1 className="-title">AWRA isn’t just an organization!</h1>
        <h3 className="-desc">
          It’s a community where you always feel welcome.
        </h3>
      </header>
      <form className="form-wrapper" onSubmit={e => handleSubmit(e)}>
        {status === "error" && (
          <div className="notification is-danger is-light">
            {message || errorMsg}
          </div>
        )}
        {status === "sent" && (
          <div className="notification is-success is-light">
            {message}
          </div>)}
        <div className="form-body">
          <p className="intro-title">
            If you’d like to apply for Membership, please fill out this form:
          </p>
          <InputField
            onChangeHandler={setName}
            type="text"
            value={name}
            placeholder="Name"
            isRequired
          />
          <InputField
            onChangeHandler={setEmail}
            type="email"
            value={email}
            placeholder="Email"
            isRequired
          />
          <div className="group-fields">
            <InputField
              onChangeHandler={setRole}
              type="text"
              value={role}
              placeholder="Role"
              isRequired
            />
            <InputField
              onChangeHandler={setTitle}
              type="text"
              value={title}
              placeholder="Title"
              isRequired
            />
          </div>
          <div className="select">
            <select value={country} onChange={handleChange}>
              {countries.map((country, i) => {
                return (
                  <option key={i} value={country.name}>
                    {country.name}
                  </option>
                )
              })}
            </select>
          </div>
          <InputField
            onChangeHandler={setBrief}
            type="textarea"
            value={brief}
            placeholder="Brief Bio"
            isRequired
          />
          <InputField
            onChangeHandler={setAchievements}
            type="textarea"
            value={achievements}
            placeholder="Achievements"
            isRequired
          />
          <InputField
            onChangeHandler={setPublishedWorks}
            type="textarea"
            value={publishedWorks}
            placeholder="Published works (if any)"
            isRequired
          />
        </div>
          <div className="button-wrapper">
            <button
              className="button is-dark"
              type="submit"
              disabled={status === "sending" ? true : false}
            >
              Submit
            </button>
          </div>
      </form>
    </section>
  )
}

export default VolunteerForm
